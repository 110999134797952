import React, { useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "./jobpageStyle.css";
import { useNavigate } from "react-router-dom";
import { lsGetUserItem } from "../../helpers/storage/local-storage";
import ApplyButton from "../../components/apply-button";
import toast from "react-hot-toast";

const LatestJob = ({ latestJob, applyJob, checkJobStatus, isApply }) => {

  const navigate = useNavigate();

  const isLoggedIn = lsGetUserItem('authenticated-token') ? true : false;
  const currentUser = lsGetUserItem('authenticated-user');

console.log(currentUser,"current user")

  const handleApplyJob = async (jobId) => {
    console.log(jobId,"jobId");

    const jobApplyData = {
      jobId,
      isApply: true,
      id: currentUser?.id
    }
     await applyJob(jobApplyData);
  
  }

  console.log(latestJob, "::::latestJob")



  return (
    <>
      <Container fluid className="latest_job__fullConatiner">
        <p className="latest_job_heading">Latest Job</p>
        {latestJob?.length && latestJob?.map((job) => (
          <Row className="latest_job__row text-center" key={job?.id}>
            <>
              <Col md={4}>
                <div className="latest_job__title">
                  <p className="jobTitle">{job.jobTitle}</p>
                  <div className="sub-title">
                    <p>Category: <span className='text_color'> {job.category}</span></p>
                    <p><span>Company:</span> <span className='text_color'>{job.companyName}</span></p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="latest_job__desc">
                  <p>{job.jobDesc}</p>
                </div>
                <div className="latest_job__location">
                  <p>Location: {job?.companyLocation}</p>
                </div>
              </Col>
              <Col md={4}>
                <ApplyButton
                  isLoggedIn={isLoggedIn}
                  isApply={isApply}
                  handleApplyJob={() => handleApplyJob(job?.id)}
                />
              </Col>
            </>
          </Row>
        ))}
      </Container>
    </>
  );
};

export default LatestJob;
