import React from "react";
import { Button, Container } from "react-bootstrap";
import './homestyle.css'; 
import { Link } from "react-router-dom";

const BigImageSection = () => {
  const bigImage = "home_about.png";
  const bigImg = process.env.PUBLIC_URL + "/images/" + bigImage;
  return (
    <Container fluid className="bigimg_fullContainer">
      <div className="image-container">
        <img src={bigImg} alt="Big" className="big-img" />
        <div className="bigImage_content">
          <p>At AS CONSULTANCY PVT LTD, we're passionate about connecting talented individuals with their dream careers. As a leading job consultancy agency, we bridge the gap between skilled professionals and top employers, fostering mutually beneficial partnerships.</p>
          <Link to='/about-page' className="link-explore">More</Link>
        </div>
      </div>
    </Container>
  );
};

export default BigImageSection;
