import React, { useContext, useEffect } from "react";
import HeroSection from "./HeroSection";
import PopularJob from "./PopularJob";
import LatestJob from "./LatestJob";
import { DataContext } from "../../context/context-job";
import {  lsGetUserItem } from "../../helpers/storage/local-storage";
import Loader from "../../components/loader";


const JobPage = ({ setIsAdmin }) => {
  const { fetchAllJob, applyJob, checkJobStatus, state: {  jobs, isLoading, isApply } } = useContext(DataContext);
  const currentUser = lsGetUserItem('authenticated-user');
  const isAuthenticated = currentUser ? true : false;

  useEffect(() => {
    (async () => {
      await fetchAllJob();
    })()
  }, [])

  

  return (
    <>
      <HeroSection isAuth={isAuthenticated} setIsAdmin={setIsAdmin} />
      <PopularJob />
      {isLoading ?
      <Loader />
      :
      <LatestJob
        latestJob={jobs}
        applyJob={applyJob}
        checkJobStatus={checkJobStatus}
        isApply={isApply}
      />
}
    </>
  );
};

export default JobPage;
