import React from 'react'
import { Container, Card, Col, Row } from 'react-bootstrap';

const Ourvalues = () => {
    const valuesData = [
        {
          desc: "Amplified Impact",
        },
        {
          desc: "Streamlined Efficiency",
        },
        {
          desc: "Continuous Growth",
        },
      ];
  return (
    <Container className='full_values_container'>
      <Row>
          <p className="values_heading">Our Values:</p>
        {valuesData.map((mission, index) => (
          <Col key={index} md={4} sm={12} className="ourvalues_conatainer__coumn">
            <div>
              <Card className="values_titleCard">
                <Card.Body>
                  <Card.Title className="values_desc">{mission.desc}</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default Ourvalues;
