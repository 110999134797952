import React, { useContext } from "react";
import AboutHeroSection from "./AboutHeroSection";
import AboutMission from "./AboutMission";
import OwnerSection from "./OwnerSection";
import OurTeam from "./OurTeam";
import Ourvalues from "./Ourvalues";
import CompaniesSection from "./CompaniesSection";
import { AuthContext } from "../../context/context-auth";


const AboutPage = () => {
  const { userState } = useContext(AuthContext);
  console.log(userState, "user")
  return (
    <>
      <AboutHeroSection />
      <AboutMission />
      <OwnerSection />
      <OurTeam />
      <Ourvalues />
      <CompaniesSection />
    </>
  );
};

export default AboutPage;
