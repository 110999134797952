import React from 'react';
import { Container } from 'react-bootstrap';

const OurTeam = () => {
    const missionImg = "missionImg.png";
    const missionImage = process.env.PUBLIC_URL + "/images/" + missionImg;

    const anuska = "anuska.png";
    const anuskaImage = process.env.PUBLIC_URL + "/images/" + anuska;

    const mohit = "mohit.png";
    const mohitImage = process.env.PUBLIC_URL + "/images/" + mohit;

    const amitabh = "amitabh.png";
    const amitabhImage = process.env.PUBLIC_URL + "/images/" + amitabh;

    return (
        <Container fluid style={{ backgroundImage: `url(${missionImage})`, backgroundSize: 'cover' }} className="full_mission_container">
            <div className="team-members">

                <div className="team-member">
                    <img src={amitabhImage} alt="Amitabh" />
                    <h5>Amitabh Thakur</h5>
                    <p>Role: CTO</p>
                </div>
                <div className="team-member">
                    <img src={mohitImage} alt="Mohit" />
                    <h5>Mohit Pandey</h5>
                    <p>Role: Designer</p>
                </div>
                <div className="team-member">
                    <img src={anuskaImage} alt="Anuska" />
                    <h5>Anuska Sinha</h5>
                    <p>Role: Developer</p>
                </div>

            </div>
        </Container>
    );
}

export default OurTeam;
