import { constant } from "./constant";

export const reducer = (state, { payload, type }) => {
    switch (type) {
        case constant.CREATE_BLOG_LOADING:
        case constant.FETCH_BLOG_LOADING:
        case constant.GET_BLOG_BY_ID_LOADING:
        case constant.UPDATE_BLOG_LOADING:
            return {
                ...state,
                isLoading: true,
                status: false,
                isError: null
            };
        case constant.CREATE_BLOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: null,
                blogs: payload.data,
                status: payload?.status,
                message: payload?.message
            };
        case constant.CREATE_BLOG_FAILURE:
            return {
                ...state,
                blogs: null,
                isLoading: false,
                message: payload?.message,
                isError: payload?.message
            };
        case constant.FETCH_BLOG_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: null,
                blogs: payload?.data,
                status: payload?.status,
                message: payload?.message
            };
        case constant.FETCH_BLOG_FAILURE:
            return {
                ...state,
                user: null,
                isLoading: false,
                message: payload?.message,
                isError: payload?.message
            };
        // -----------------JOB ------------------------ //

        case constant.CREATE_JOB_LOADING:
        case constant.FETCH_JOB_LOADING:
            return {
                ...state,
                isLoading: true,
                status: false,
                isError: null
            };
        case constant.CREATE_JOB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: null,
                jobs: payload.data,
                status: payload?.status,
                message: payload?.message
            };
        case constant.CREATE_JOB_FAILURE:
            return {
                ...state,
                isLoading: false,
                message: payload?.message,
                isError: payload?.message,
                jobs: null
            };
        case constant.FETCH_JOB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: null,
                jobs: payload?.data,
                status: payload?.status,
                message: payload?.message
            };
        case constant.FETCH_JOB_FAILURE:
            return {
                ...state,
                jobs: null,
                isLoading: false,
                message: payload?.message,
                isError: payload?.message
            };
        // -----------------JOB APPLY AND CHECK STATUS ------------------------ //

        case constant.APPLY_JOB_LOADING:
        case constant.GET_JOB_STATUS_LOADING:
            return {
                ...state,
                isLoading: true,
                status: false,
                isError: null
            };
        case constant.APPLY_JOB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: null,
                isApply: payload.data,
                status: payload?.status,
                message: payload?.message
            };
        case constant.APPLY_JOB__FAILURE:
            return {
                ...state,
                isLoading: false,
                message: payload?.message,
                isError: payload?.message,
                isApply: null
            };
        case constant.GET_JOB_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: null,
                isApply: payload,
                status: payload?.status,
                message: payload?.message
            };
        case constant.GET_JOB_STATUS_FAILURE:
            return {
                ...state,
                isApply: null,
                isLoading: false,
                message: payload?.message,
                isError: payload?.message
            };
        default:
            return state;
    }
}