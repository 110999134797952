import React from 'react';
import './homestyle.css'; 
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HomeHeroSection = () => {
  const homeHeroImg = "home.png";
  const homeHero = process.env.PUBLIC_URL + "/images/" + homeHeroImg;

  return (
    <div className='heroSection'>
      <div className='hero_content__container' style={{
        backgroundImage: ` url(${homeHero})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '150vh', 
        backgroundBlendMode: 'soft-light', 
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div className='hero_content'>
        <h4>Your Personalized Path<br/> to Career Success</h4>
        <p>Find Your Dream Job with AS CONSULTANCY</p>
        <Link to='/job' className="link-explore">Find Job</Link>
        </div>
      </div>
    </div>
  );
};

export default HomeHeroSection;
