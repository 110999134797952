import React, { useContext, useEffect } from 'react'
import HomeHeroSection from './HomeHeroSection'
import EnrollSection from './EnrollSection'
import BigImageSection from './BigImageSection'
import SuccessImageSection from './SuccessImageSection'
import BlogSection from './BlogSection'
import { DataContext } from '../../context/context-job'

const HomePage = () => {
  const { fetchAllBlog, state: { blogs, jobs, isLoading } } = useContext(DataContext);

  useEffect(() => {
    (async () => {
      await fetchAllBlog();
    })()
  }, []);



  return (
    <>
      <HomeHeroSection />
      <EnrollSection />
      <BigImageSection />
      <BlogSection blogs={blogs} blogLoading={isLoading} />
      <SuccessImageSection />
    </>
  )
}

export default HomePage
