export const lsSetItemFn = (key, value) => {
    console.log("hhhhhhhhhhhh")
    return localStorage.setItem(key, JSON.stringify(value))
}

export const lsGetUserItem = (key) => {
    const item = localStorage.getItem(key);
    if (item !== undefined && item !== null) {
        return JSON.parse(item);
    }
    return null;
}



export const lsRemoveItem = (key) => {
    return localStorage.removeItem(key)
}

export const accessChecker = (expireTime) => {
    const currentTime = new Date().getTime();
    if (expireTime && currentTime > parseInt(expireTime)) {
        console.log("logout");
    }
}