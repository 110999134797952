import React from 'react'
import { NavLink } from 'react-router-dom'
import { DASHBOARD } from '../helpers/sidebar-data/route-urls'

const SidebarHeader = ({ menuActive }) => {
    return (
        <>
            <div className="logo-section d-flex align-items-center">
                <NavLink to={DASHBOARD}>
                    <span className={`logo-text text-dark ${menuActive ? 'inline-block' : 'visually-hidden'}`}>AS<strong style={{ color: 'crimson' }}>Consultant</strong></span>
                </NavLink>
            </div>
        </>
    )
}

export default SidebarHeader