import React, { useContext, useEffect, useState } from 'react'
import BlogForm from './blog-form'
import { useNavigate } from 'react-router-dom';
import { lsGetUserItem } from '../../../helpers/storage/local-storage';
import { DataContext } from '../../../context/context-job';



const CreateBlog = () => {
    const currentUser = lsGetUserItem('authenticated-user');

    const initialValue = {
        title: "",
        description: "",
        image: null,
        author: currentUser?.fullname,
    }

    const [file, setFile] = useState(null);
    const [blogValue, setBlogValue] = useState(initialValue);
    const { createBlog } = useContext(DataContext)
    // const navigate = useNavigate();

    console.log(file, "::::file")

    // useEffect(() => {
    //     if (file) {
    //         setBlogValue((prevVal) => (
    //             {
    //                 ...prevVal,
    //                 image: file
    //             }
    //         ))
    //     }
    // }, [file])



    const handleChange = (e) => {
        const { name, value } = e.target;
        setBlogValue((prevVal) => {
            return {
                ...prevVal,
                [name]: value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('title', blogValue.title);
        data.append('description', blogValue.description);
        data.append('author', blogValue.author);
        data.append('image', file);

        await createBlog(data);
        setBlogValue(initialValue);
        setFile(null)
    }

    return (
        <>
            <BlogForm
                type="create"
                blogValue={blogValue}
                handleChange={handleChange}
                onSubmit={handleSubmit}
                setFile={setFile}
            />
        </>
    )
}

export default CreateBlog
