

import React from 'react'
import { useReducer } from 'react';
import { createContext } from 'react'
import { userReducer } from './user-reducer';
import { constant } from './constant';
import { lsRemoveItem, lsSetItemFn } from '../../helpers/storage/local-storage';
import { useNavigate } from 'react-router-dom';
import { LOG_OUT } from '../../helpers/sidebar-data/route-urls';
import toast from 'react-hot-toast';
import createAxiosInstance from '../../helpers/axios/private-instance';

const initialState = {
  user: null,
  status: false,
  isLoading: false,
  isError: null,
  message: ''
}

// const BASE_URL = 'http://localhost:8000/api'
// const BASE_URL = 'https://asbusinesses.in:8000/api' 
const LOGIN = '/users/login'
const REGISTER = '/users/register'
// const REGISTER = '/user/register-user'
const LOGOUT = '/users/logout-user'


export const AuthContext = createContext();

const AuthProvider = ({ children }) => {

  const [userState, userDispatch] = useReducer(userReducer, initialState);
  const navigate = useNavigate();

  /**
   * @register | user function
   * @param {*} user | user register Data
   * @Author | Anuska Sinha
   */
  const registerUser = async (user) => {
    userDispatch({ type: constant.REGISTER_LOADING });
    try {
      const axiosPrivateInstance = createAxiosInstance(user)
      const response = await axiosPrivateInstance.post(REGISTER, user);
      userDispatch({ type: constant.REGISTER_SUCCESS, payload: response.data.user })
      if (response?.data?.status) {
        navigate('/job')
      }
    } catch (error) {
      userDispatch({ type: constant.REGISTER_FAILURE, payload: error.message })
    }
  }


  /**
   * @login | user function
   * @param {*} user | user login data
   * @Author | Anuska Sinha
   */

  const loginUser = async (user, setIsAdmin) => {
    userDispatch({ type: constant.LOGIN_LOADING });
    let response;
    try {
      const axiosPrivateInstance = createAxiosInstance(user)
      response = await axiosPrivateInstance.post(LOGIN, user);
      response = response?.data;
      setIsAdmin(response?.user?.isAdmin)
      lsSetItemFn('authenticated-user', response?.user)
      lsSetItemFn('authenticated-token', response?.accessToken)
      userDispatch({ type: constant.LOGIN_SUCCESS, payload: response });
      if (response?.user) {
        toast.success(response?.message)
        if (response?.user?.isAdmin) {
          navigate('/admin')
        } else {
          navigate('/about-page')
        }
      }
    } catch (error) {
      console.log(error, "error")

      userDispatch({ type: constant.LOGIN_FAILURE, payload: response })
    }
  }
  /**
   * @logout | user function
   * @Author | Anuska Sinha
   */

  const logOutUser = async (user) => {
    userDispatch({ type: constant.LOGOUT_LOADING });
    try {
      const axiosPrivateInstance = createAxiosInstance(user)
      const response = await axiosPrivateInstance.post(LOGOUT, user);
      lsRemoveItem('authenticated-user')
      lsRemoveItem('authenticated-token')
      userDispatch({ type: constant.LOGIN_SUCCESS, payload: null })
    } catch (error) {
      userDispatch({ type: constant.LOGOUT_FAILURE, payload: error.message })
    }
  }


  const handleLogoutUser = (path) => {
    if (path === LOG_OUT) {
      lsRemoveItem('authenticated-user');
      lsRemoveItem('authenticated-token');
      toast.success("Logout Successfully!!");
      navigate('/job')
    }
  }






  const value = {
    userState,
    userDispatch,
    registerUser,
    loginUser,
    logOutUser,
    handleLogoutUser
  }


  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
