import React from "react";
import { Button, Container } from "react-bootstrap";
import "./jobpageStyle.css";
import TopCarousel from "./TopCarousel";
import { Link } from "react-router-dom";
import Login from "../Auth/Login";
const HeroSection = ({ isAuth, setIsAdmin }) => {
  const heroImg = "jobHero.png";
  const image = process.env.PUBLIC_URL + "/images/" + heroImg;
  return (
    <>
      <Container
        fluid
        className="hero_section__fullContainer"
        style={{ backgroundImage: `url(${image})` }}
      >
        <Container>

          <p className="title">Find Jobs, Employment & Career Opportunities</p>
          <p className="paraInfo">
            Trusted by Candidates | Employers
          </p>
        </Container>
        {isAuth ?
          ''
          :
          <div className="heroSection_button">
            <Button className="register_btn">
              <Link to="/register">Register</Link>
            </Button>
            <div>
              <Login setIsAdmin={setIsAdmin} />
            </div>
          </div>
        }
        {/* <div className="heroSection_button">
          <Button className="register_btn">
            <Link to="/register">Register</Link>
          </Button>
          <div>
            <Login />
          </div>
        </div> */}
        <div className="marqu-slider">
          <TopCarousel />
        </div>
      </Container>
    </>
  );
};

export default HeroSection;
