import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import "./jobpageStyle.css";


const PopularJob = () => {
  const Products = [
    {
      desc: "Job for fresher",
    },
    {
      desc: "Work from home Jobs",
    },
    {
      desc: "Part time Jobs",
    },
    {
      desc: "Jobs for Women",
    },
    {
      desc: "International Jobs",
    },
    
  ];

  return (
    <>
      <Row className="jobRow">
        <Col sm={12} md={4}>
          <p className="job_heading">Popular Searches</p>
        </Col>
        {Products.map((Product, Index) => (
          <Col key={Index} sm={12} md={4}>
            <Card className="job_titleCard">
              <Card.Body>
                <Card.Title className="job_desc">{Product.desc}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PopularJob;
