import React from 'react'

import { FormButton, FormContainerStyle, FormControls, FormHeader, FormStyle, PictureLabelStyle } from '../styled';
import InputField from '../../../components/inputs/input-field';
import TextAreaField from '../../../components/inputs/textarea-field';
const BlogForm = (props) => {
    const { type, blogValue, handleChange, onSubmit, setFile } = props;
    console.log(blogValue, "blogValue")

    const isEdit = type === "create";



    return (
        <FormContainerStyle>
            <FormStyle onSubmit={onSubmit}>
                <FormHeader>{isEdit ? 'Create' : 'Update'} Blog</FormHeader>

                <FormControls>
                    <InputField
                        type={'text'}
                        placeholder={'Enter your title'}
                        id="title"
                        name='title'
                        value={blogValue?.title}
                        onChange={handleChange}
                    />
                </FormControls>
                <FormControls>
                    <TextAreaField
                        type={'text'}
                        placeholder={'Enter your Description'}
                        name='description'
                        id="description"
                        value={blogValue?.description}
                        onChange={handleChange}

                    />
                </FormControls>
                <FormControls>
                    <TextAreaField
                        type={'text'}
                        placeholder={'Enter  author name'}
                        name='author'
                        id="author"
                        value={blogValue?.author}
                        onChange={handleChange}

                    />
                </FormControls>

                <input
                    id='blogImage'
                    type={'file'}
                    name='image'
                    style={{ display: 'block' }}
                    onChange={(e) => setFile(e.target.files[0])}

                />
                <FormControls className='t-center'>
                    <FormButton type='submit' >{isEdit ? 'Publish' : 'Update'}</FormButton>
                </FormControls>
            </FormStyle>
        </FormContainerStyle>
    )
}

export default BlogForm