import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/context-auth";
import './inputStyle.css';
const initialState = {
  email: '',
  password: ''
}

const Login = ({ setIsAdmin }) => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [inputs, setInputs] = useState(initialState);
  const { loginUser, userState } = useContext(AuthContext);
  const navigate = useNavigate()

  const handleClose = () => {
    setShow(false);
    setInputs(initialState)
    setError("");
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setInputs((preState) => ({
      ...preState,
      [name]: value
    }));
  }

  const handleShow = () => setShow(true);

  const handleLogin = async () => {
    // Authentication successful
    await loginUser(inputs, setIsAdmin);
    handleClose();
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="login_btn">
        Login
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="login_btn">Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicUsername" className="form_wrapper__username">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                className="input-field"
                name="email"
                placeholder="Enter your email"
                value={inputs?.email}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="form_wrapper">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                className="input-field"
                placeholder="Enter your password"
                value={inputs?.password}
                onChange={handleChange}
              />
            </Form.Group>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleLogin} className="w-100 login-btn">
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Login;
