import React from "react";
import './homestyle.css'; 

const SuccessImageSection = () => {
  const successImage = "successImage.png";
  const successImg = process.env.PUBLIC_URL + "/images/" + successImage;

  return (
    <div
      className="successSection"
      style={{ backgroundImage: `url(${successImg})` }}
    >
      <h4>
        “Success is not final, failure is not fatal: it is the courage to
        continue that counts.”
      </h4>
    </div>
  );
};

export default SuccessImageSection;
