import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import './homestyle.css';

const ProductCard = ({ Title, Description }) => (
  <Card className="enroll_titleCard">
    <Card.Body>
      <Card.Title className="enroll_title">{Title}</Card.Title>
      <Card.Text className="enroll_desc">{Description}</Card.Text>
    </Card.Body>
  </Card>
);
const ProductHeading = ({ Title }) => (
  <Card className="full_enroll_card">
    <Card.Body className="enroll_heading_card">
      <Card.Title className="enroll_heading">{Title}</Card.Title>
    </Card.Body>
  </Card>
);
const EnrollSection = () => {
  const enrollData = [
    {
      Title: "Personalized Matchmaking",
      Description:
        "Forget generic job boards and mass applications. We take the time to understand you, your unique skills, aspirations, and career goals. We match you with opportunities that perfectly align, not just fill a vacancy.",
    },
    {
      Title: "Industry Expertise",
      Description:
        "Our experienced consultants aren't just resume pushers; they're industry insiders. We leverage our extensive network and deep understanding of diverse sectors to open doors you wouldn't find on your own.",
    },
    {
      Title: "Tailored Support",
      Description:
        "You're not alone in this journey. From resume and cover letter tailoring to interview coaching and salary negotiation guidance, we offer comprehensive support tailored to your specific needs.",
    },
    {
      Title: "Honest Feedback & Transparency",
      Description:
        "We believe in building trust through open communication and genuine feedback. We'll equip you with valuable insights to hone your skills and confidently navigate the job market.",
    },
  ];
  return (
    <>
      <Row className="enrollRow">
        <Col sm={12} md={6}>
          <ProductHeading Title="why 
AS CONSULTANCY stands out ?" />
        </Col>
        {enrollData.map((Product, Index) => (
          <Col key={Index} sm={12} md={4}>
            <ProductCard {...Product} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default EnrollSection;
