import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useContext, useEffect } from "react";
import "./header.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/context-auth";
import { lsGetUserItem } from "../../helpers/storage/local-storage";

function Header() {
  const logoImage = "logo.png";
  const logo = process.env.PUBLIC_URL + "/images/" + logoImage;
  const phoneImg = "phImage.png";
  const phImage = process.env.PUBLIC_URL + "/images/" + phoneImg;
  const isAuth = lsGetUserItem('authenticated-user') || null;
  const { handleLogoutUser } = useContext(AuthContext);


  return (
    <header className="sticky-top">
      {/* Add sticky-top class here */}
      <Container fluid className="top_menu">
        <p>
          Apna launches International jobs 🎉
          <span className="top_content">
            Check out now
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                d="M15.935 8.71737C16.3312 8.32118 16.3312 7.67882 15.935 7.28263L9.47872 0.826329C9.08253 0.430138 8.44017 0.430138 8.04398 0.826329C7.64779 1.22252 7.64779 1.86487 8.04398 2.26106L13.7829 8L8.04398 13.7389C7.64779 14.1351 7.64779 14.7775 8.04398 15.1737C8.44017 15.5699 9.08253 15.5699 9.47872 15.1737L15.935 8.71737ZM0 9.01451L15.2177 9.01451V6.98549L0 6.98549L0 9.01451Z"
                fill="white"
              />
            </svg>
          </span>
        </p>
      </Container>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="#" className="logo">
            <Link to="/">
              <img src={logo} alt="Logo" />{" "}
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto header_navbar"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <NavLink to="/job" className='menu_nav'>Jobs</NavLink>
              {/* <NavLink to="/blogs" className='menu_nav'>Blogs</NavLink> */}
              <NavLink to="/about-page" className='menu_nav'>About</NavLink>
              {isAuth && <NavLink to="/logout" onClick={() => handleLogoutUser('/logout')} className='menu_nav'>Logout</NavLink>}
              <NavLink to="tel:+919354541056" className="ph_number">
                <img src={phImage} className="header_ph_image" /> +91 9818555881
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
