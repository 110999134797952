import { faChevronDown, faChevronLeft, faCode, faCodeBranch, faContactCard, faDashboard, faList, faSatellite, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CREATE_BLOG, CREATE_JOB, DASHBOARD, LOG_OUT, VIEW_BLOGS, VIEW_JOBS } from "./route-urls";
export const sidebarData = [
    {
        'id': 1,
        'title': 'Dashboard',
        'path': DASHBOARD,
        'icon': <FontAwesomeIcon icon={faDashboard} />,
        'openIcon': <FontAwesomeIcon icon={faChevronLeft} />,
        'closeIcon': <FontAwesomeIcon icon={faChevronDown} />
    },
    {
        'id': 2,
        'title': 'Create Job',
        'path': CREATE_JOB,
        'icon': <FontAwesomeIcon icon={faList} />,
        'openIcon': <FontAwesomeIcon icon={faChevronLeft} />,
        'closeIcon': <FontAwesomeIcon icon={faChevronDown} />,
        'submenu': [
            {
                'id': 21,
                'title': 'Create Job',
                'path': CREATE_JOB,
                'icon': <FontAwesomeIcon icon={faCode} />,
            },
            {
                'id': 22,
                'title': 'View Jobs',
                'path': VIEW_JOBS,
                'icon': <FontAwesomeIcon icon={faCodeBranch} />,
            },
        ]
    },
    {
        'id': 3,
        'title': 'Blogs',
        'path': CREATE_BLOG,
        'icon': <FontAwesomeIcon icon={faSatellite} />,
        'openIcon': <FontAwesomeIcon icon={faChevronLeft} />,
        'closeIcon': <FontAwesomeIcon icon={faChevronDown} />,
        'submenu': [
            {
                'id': 23,
                'title': 'Create Blog',
                'path': CREATE_BLOG,
                'icon': <FontAwesomeIcon icon={faCode} />,
            },
            {
                'id': 24,
                'title': 'View Blogs',
                'path': VIEW_BLOGS,
                'icon': <FontAwesomeIcon icon={faCodeBranch} />,
            },
        ]
    },
    {
        'id': 3,
        'title': 'Logout',
        'path': LOG_OUT,
        'icon': <FontAwesomeIcon icon={faSignOut} />,
        'openIcon': <FontAwesomeIcon icon={faChevronLeft} />,
        'closeIcon': <FontAwesomeIcon icon={faChevronDown} />,
    },
]