import { constant } from "./constant";

export const userReducer = (state, action) => {
    switch (action.type) {
        case constant.LOGIN_LOADING:
        case constant.REGISTER_LOADING:
        case constant.LOGOUT_LOADING:
            return {
                ...state,
                isLoading: true,
                status: false,
                isError: null
            };
        case constant.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.payload?.user,
                message: action.payload?.message,
                status: action.payload?.status,
                isLoading: false,
                isError: null
            };
        case constant.LOGIN_FAILURE:
            return {
                ...state,
                user: null,
                isLoading: false,
                isError: action?.payload?.message
            };
        case constant.REGISTER_SUCCESS:
            return {
                ...state,
                user: action.payload?.user,
                message: action.payload?.message,
                status: action.payload?.status,
                isLoading: false,
                isError: null
            };
        case constant.REGISTER_FAILURE:
            return {
                ...state,
                user: null,
                isLoading: false,
                status: action.payload?.status,
                isError: action?.payload?.message
            };
        case constant.LOGOUT_SUCCESS:
            return {
                ...state,
                user: null,
                isLoading: false,
                isError: null
            };
        case constant.LOGOUT_FAILURE:
            return {
                ...state,
                user: null,
                isLoading: false,
                isError: null
            };
        default:
            return state;
    }
}