import axios from 'axios';
import {  lsGetUserItem } from '../storage/local-storage';


const createAxiosInstance = (data) => {
    let contentType;

    // Check if FormData is being sent
    if (data instanceof FormData) {
        contentType = 'multipart/form-data';
    } else {
        contentType = 'application/json';
    }

    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
            'Content-Type': contentType
        }
    });

    axiosInstance.interceptors.request.use(
        config => {
            const token = lsGetUserItem('authenticated-token')
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
}

export default createAxiosInstance;