import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataContext } from '../../context/context-job';
import './homestyle.css'
const DetailBlogpage = () => {
    const [blog, setBlog] = useState({})
    const { id } = useParams();
    const { fetchAllBlog, state: { blogs, isLoading } } = useContext(DataContext)
console.log(blogs,"blogss")
    useEffect(() => {
        // (async () => {
        //     await fetchAllBlog();
        // })()
        const blogData = blogs?.find((item) => item?.id == id)
        setBlog(blogData)
    }, [id])
    console.log(id, "id detail", blog)

    return (
        <div>
            <div className='detail_blog__container'>
                <h2>{blog?.title}</h2>
                <p>{blog?.description}</p>
            </div>
        </div>
    );
};

export default DetailBlogpage;
