import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import './homestyle.css';
import { useNavigate } from 'react-router-dom';
import { addEllipsis } from "../../utils";

const BlogSection = ({ blogs, blogLoading }) => {
  const navigate = useNavigate();

  if (blogLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <Container fluid className="blog__fullContainer">
      <Row>
        <section className="blog_section">
          <h4>Resources and Blogs</h4>
          <p>
            Expand your knowledge toolkit by reading from the most prominent
            experts
          </p>
        </section>

        {blogs.map((blog) => (
          <Col md={4} key={blog.id} onClick={() => navigate(`blog-details/${blog?.id}`)}>
            <Card className="full_blogCard">
              <Card.Img variant="top" src={blog.image} className="blog_image" />
              <Card.Body>
                <Card.Title className="blogCard_title">{blog.title}</Card.Title>
                <Card.Text className="blogCard_desc">
                  {addEllipsis(blog.description,200)}
                </Card.Text>                {/* <Card.Text className="blogCard_">{blog.author}</Card.Text> */}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
export default BlogSection


