import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AboutPage from '../components/aboutUs/AboutPage'
import HomePage from '../components/home/HomePage'
import PrivateRoute from './private-route'
import JobPage from '../pages/job/JobPage'
import CreateJob from '../pages/admin/jobs/create'
import AdminPanel from '../pages/admin/admin-panel'
import CreateBlog from '../pages/admin/blogs/create'
import BlogPage from '../pages/admin/blogs'
import JobPages from '../pages/admin/jobs'
import Register from '../pages/Auth/Register'
import DetailBlogpage from './../components/home/DetailBlogpage';

const Routing = ({ setIsAdmin, isAdmin }) => {


    return (
        <div id="main">
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/register" element={<Register />} />
                <Route path="/job" element={<JobPage setIsAdmin={setIsAdmin} />} />
                <Route path="/about-page" element={<AboutPage />} />
                <Route path="/blog-details/:id" element={<DetailBlogpage />} />
                <Route path="/job-create" element={<CreateJob />} />
                <Route element={<PrivateRoute isAdmin={isAdmin} />} >
                    <Route path="/admin" element={<AdminPanel />} />
                    <Route path="/create-job" element={<CreateJob />} />
                    <Route path="/view-jobs" element={<JobPages />} />
                    <Route path="/create-blog" element={<CreateBlog />} />
                    <Route path="/view-blogs" element={<BlogPage />} />
                </Route>
            </Routes>
        </div>
    )
}

export default Routing
