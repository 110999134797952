import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const CompaniesSection = () => {
    const companyData = [
        {
            name: "Aero Consultants",
        },
        {
            name: "Children Village (NGO)",
        },
        {
            name: "Recognitive Technologies",
        },
        {
            name: "Gaur Sons",
        },
        {
            name: "V&G Building Design consultants",
        },
        {
            name: "Web Infraprojects",
        },
        {
            name: "One Design & Consultats",
        },
    ];

    return (
        <Container className='company_full_container'>
            <h4>We Work With the Very Best</h4>
            <Container className='company_main_container'>
                <Row>
                    {companyData.map((company, index) => (
                        <Col key={index} sm={6} md={4} lg={3} className='mb-3'>
                            <div className='company_section'>{company.name}</div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    );
};

export default CompaniesSection;
