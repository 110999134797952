import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./aboutstyle.css";
const OwnerSection = () => {
  const owner = "manikant.png";
  const ownerImg = process.env.PUBLIC_URL + "/images/" + owner;
  return (
    <>
      <Container fluid className="aboutUs__fullContainer">
        <Row>
          <Col md={6} className="about_heroContent__container">
            <h6 className="title">Hey! Friends and colleagues,</h6>
            <p>
            It's truly humbling to look around and see so many faces who have played a part in building AS CONSULTANCY PVT LTD. What began as a spark of an idea to connect exceptional people with fulfilling careers has blossomed into a force truly impacting individual lives.
            </p>
            <p>We don't just fill positions; we build bridges. By understanding each person's unique aspirations and strengths, we meticulously craft paths to their dream careers. Seeing their journeys blossom, filled with confidence and purpose, is our greatest reward.</p>
            <p>
              This is a great space to write a long text about your company and
              your services. You can use this space to go into a little more
              detail about your company. Talk about your team and what services
              you provide. Tell your visitors the story of how you came up with
              the idea for your business and what makes you different from your
              competitors. Make your company stand out and show your visitors
              who you are.
            </p>
            <p>But the future holds even more. We're constantly evolving, embracing new technologies to empower individuals and bridge talent gaps even further. Together, we'll continue to shape a dynamic and fulfilling work environment for all.
Thank you for being a part of this exciting journey. Let's continue to write our success story, chapter by chapter.</p>
          </Col>
          <Col md={6} className="about_hero_imageContainer"><img src={ownerImg}/></Col>
        </Row>
      </Container>
    </>
  );
};

export default OwnerSection;
