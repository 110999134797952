import React, { useContext, useEffect, useState } from 'react'

import { Card, Col, Container, Row } from 'react-bootstrap'
import { DataContext } from '../../../context/context-job';
import Loader from '../../../components/loader';
import './blog.css'




const BlogPage = () => {
    const [searchQuery, setSearchQuery] = useState("")
    const { fetchAllBlog, state: { blogs, isLoading } } = useContext(DataContext);


    useEffect(() => {
        (async () => {
            await fetchAllBlog()
        })()
    }, []);




    // if (isError) {
    //     return <h1>{error?.message}</h1>
    // }
    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <Container className='p-2'>
                <Row>
                    {
                        blogs?.length && blogs?.map((blog) => (
                            <Col md={3} key={blog?.id}>
                                <Card className='admin_viewBlog'>
                                    <Card.Header>{blog?.title}</Card.Header>
                                    <Card.Body>
                                        <Card.Img variant="top" src={blog?.image} alt="Blog Image" />
                                        <Card.Subtitle className='admin_viewDesc'>{blog?.description}</Card.Subtitle>
                                        <Card.Title>{blog?.author}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </Container>

        </>
    )
}

export default BlogPage