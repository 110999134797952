import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import JobForm from './job-form';
import { DataContext } from '../../../context/context-job';
import { lsGetUserItem } from '../../../helpers/storage/local-storage';
import toast from 'react-hot-toast';
import { VIEW_JOBS } from '../../../helpers/sidebar-data/route-urls';


const initialValue = {
    jobTitle: '',
    jobDesc: '',
    applicantExp: '',
    companyName: '',
    companyLocation: '',
    requirements: '',
    category: '',
}

const CreateJob = () => {

    const [jobValue, setJobValue] = useState(initialValue);
    const navigate = useNavigate();
    const currentUser = lsGetUserItem('authenticated-user');
    const { createJob } = useContext(DataContext);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setJobValue((prevVal) => {
            return {
                ...prevVal,
                [name]: value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const jobData = {
            ...jobValue,
            createdBy: currentUser?._id
        }
        const res = await createJob(jobData)
        if (res?.status) {
            toast.success(res?.message);
            navigate(VIEW_JOBS)
            setJobValue(initialValue)
        } else {
            toast("Something went wrong while creating job!!")
        }
    }

    return (
        <>
            <JobForm
                type="create"
                jobValue={jobValue}
                handleChange={handleChange}
                onSubmit={handleSubmit}
            />
        </>
    )
}

export default CreateJob
