import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Container, FormLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/context-auth";
import { toast } from "react-hot-toast";
import Loader from "../../components/loader";

const initialState = {
  fullname: "",
  email: "",
  phone: "",
  password: "",
  resume: null,
  isAdmin: false
};

const Register = () => {
  const bgImage = "registerbg.png";
  const registerbg = process.env.PUBLIC_URL + "/images/" + bgImage;
  const [formData, setFormData] = useState(initialState);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { registerUser } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email) {
      toast.error('Please enter your email');
      return;
    }

    setIsLoading(true);

    try {
      await registerUser(formData);
      toast.success('Registration successful!');
      setFormData(initialState);
    } catch (error) {
      console.error(error);
      toast.error('Registration failed. Please try again.');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (file) {
      setFormData((prev) => ({ ...prev, resume: file }));
    }
  }, [file]);

  return (
    <div
      style={{ backgroundImage: `url(${registerbg})` }}
      className="full_register__wrapper"
    >
      <Container className="register__conatiner">
        <h2 className="register__title">Registration</h2>
        <Form onSubmit={handleSubmit} className="register__form">
          <Form.Group controlId="fullname" className="register_form__grp">
            <Form.Label className="register_label">Full Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your full name"
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
              className="register_input_field"
            />
          </Form.Group>

          <Form.Group controlId="email" className="register_form__grp">
            <Form.Label className="register_label">Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="register_input_field"
            />
          </Form.Group>

          <Form.Group controlId="phone" className="register_form__grp">
            <Form.Label className="register_label">Mobile Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your mobile number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="register_input_field"
            />
          </Form.Group>

          <Form.Group controlId="password" className="register_form__grp">
            <Form.Label className="register_label">Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="register_input_field"
            />
          </Form.Group>

          <FormLabel className="resume">Resume</FormLabel>
          <Form.Group className="mb-3 text-center justify-content-center upload-resume">
            <Form.Control
              id="resume"
              type="file"
              accept=".doc, .docx, .pdf, .rtf"
              onChange={(e) => setFile(e.target.files[0])}
              style={{ display: "none" }}
            />
            <label htmlFor="resume" className="custom-file-upload">
              Upload Resume
              <p className="text-center">DOC, DOCx, PDF, RTF | Max: 2 MB</p>
            </label>
          </Form.Group>

          <p className="below_text">
            Recruiters give first preference to candidates who have a resume
          </p>
          {/* <p className="register_below_text">
            <b>NOTE:-</b> By clicking Register, you agree to the{" "}
            <Link to="/">Terms and Conditions</Link> &{" "}
            <Link to="/">Privacy Policy</Link> of As Consultants
          </p> */}

          <Button type="submit" className="w-100" disabled={isLoading}>
            {isLoading ? <Loader /> : 'Register'}
          </Button>
          
          {/* Link to login */}
          {/* <p className="mt-3">Now Login from here <Link to='/login'>Login</Link></p> */}
        </Form>
      </Container>
    </div>
  );
};

export default Register;
