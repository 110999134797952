import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Routing from './routing/routing';
import AuthProvider from './context/context-auth';
import DataProvider from './context/context-job';
import { Toaster } from 'react-hot-toast';
import Sidebar from './layout/sidebar';
import { lsGetUserItem } from './helpers/storage/local-storage';

function App() {
  const user = lsGetUserItem('authenticated-user');
  const [isAdmin, setIsAdmin] = useState(user?.isAdmin);
  const [menuActive, setMenuActive] = useState(true);
  console.log(isAdmin, ":::::::isAdmin")
  useEffect(() => {

  }, [user]);

  const handleSidebar = () => {
    setMenuActive(!menuActive)
  }

  return (
    <Router>
      <AuthProvider>
        <DataProvider>
          {!isAdmin ? <Header /> : ""}
          {isAdmin ? <Sidebar menuActive={menuActive} setIsAdmin={setIsAdmin} /> : ''}
          <div className={`main-wrapper ${(!isAdmin) ? "" : "mainActive"}`}>
            <Routing setIsAdmin={setIsAdmin} isAdmin={isAdmin} />
          </div>
        </DataProvider>
      </AuthProvider>
      {!isAdmin ? <Footer /> : ""}
      <Toaster />
    </Router>
  );
}

export default App;
