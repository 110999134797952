export const constant = {

    // -----------------BLOG ------------------------ //

    CREATE_BLOG_LOADING: 'create_blog_loading',
    CREATE_BLOG_SUCCESS: 'create_blog_success',
    CREATE_BLOG_FAILURE: 'create_blog_failure',


    FETCH_BLOG_LOADING: 'fetch_blog_loading',
    FETCH_BLOG_SUCCESS: 'fetch_blog_success',
    FETCH_BLOG_FAILURE: 'fetch_blog_failure',


    GET_BLOG_BY_ID_LOADING: 'get_blog_by_id_loading',
    GET_BLOG_BY_ID_SUCCESS: 'get_blog_by_id_success',
    GET_BLOG_BY_ID_FAILURE: 'get_blog_by_id_failure',

    UPDATE_BLOG_LOADING: 'update_blog_loading',
    UPDATE_BLOG_SUCCESS: 'update_blog_success',
    UPDATE_BLOG_FAILURE: 'update_blog_failure',



    // -----------------JOB ------------------------ //
    CREATE_JOB_LOADING: 'create_job_loading',
    CREATE_JOB_SUCCESS: 'create_job_success',
    CREATE_JOB_FAILURE: 'create_job_failure',


    FETCH_JOB_LOADING: 'fetch_job_loading',
    FETCH_JOB_SUCCESS: 'fetch_job_success',
    FETCH_JOB_FAILURE: 'fetch_job_failure',



    // -----------------JOB APPLY AND CHECK STATUS------------------------ //

    APPLY_JOB_LOADING: 'apply_job_loading',
    APPLY_JOB_SUCCESS: 'apply_job_success',
    APPLY_JOB__FAILURE: 'apply_job_failure',

    GET_JOB_STATUS_LOADING: 'get_job_status_loading',
    GET_JOB_STATUS_SUCCESS: 'get_job_status_success',
    GET_JOB_STATUS_FAILURE: 'get_job_status_failure',
}