import React, { useContext } from 'react'
import './sidebar.css';
import { sidebarData as menuData } from '../helpers/sidebar-data'
import SidebarHeader from './sidebar-header';
import MenuItem from './menu-item';
import { LOG_OUT } from '../helpers/sidebar-data/route-urls';
import { lsRemoveItem } from '../helpers/storage/local-storage';
import toast from 'react-hot-toast';
import { AuthContext } from '../context/context-auth';
const Sidebar = ({ menuActive, setIsAdmin }) => {
    const { handleLogoutUser } = useContext(AuthContext)


    const handleLogout = (path) => {
        handleLogoutUser(path)
        setIsAdmin(false)
    }

    return (
        <>
            <div className={`${menuActive ? 'sidebar' : 'sidebar sidebarActive'}`}>
                <SidebarHeader menuActive={menuActive} />
                <div className="sidebar-innerContainer">
                    <ul className="menu-bar">
                        {menuData?.map((menu) => (
                            <MenuItem
                                menu={menu}
                                menuActive={menuActive}
                                index={menu?.id}
                                key={menu?.id}
                                handleLogout={handleLogout}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </>
    )

}

export default Sidebar