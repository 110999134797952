import { css, styled } from 'styled-components';

export const FlexContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FlexAlign = css`
  display: flex;
  align-items: center;
`;


export const FormContainerStyle = styled.div`
    ${FlexCenter}
    min-height: 84.5vh;
    background: #fff;
    padding: 10px;
`

export const FormStyle = styled.form`
    max-width: 500px;
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 3px 2px 2px rgba(0,0,0,0.5);
`
export const FormHeader = styled.div`
    font-size: 25px;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: center;
`
export const FormControls = styled.div`
    position: relative;
    margin-bottom: 15px;
    
    `
export const GlobalFilterStyle = styled.div`
  margin-bottom: 15px;
  max-width: 520px;
  width: 100%;
  input{
    color: #fcaf3b !important;
  }
`


export const EyeIcon = styled.span`
  position: absolute;
  cursor: pointer;
  top: 7px;
  right: 10px;
`
export const FormInput = styled.input`
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: 0.4px solid #c9c9c9;
    font-size: 16px;
    border-radius: 6px;
`
export const FormTextArea = styled.textarea`
    width: 100%;
    padding: 10px 15px;
    outline: none;
    border: 0.4px solid #c9c9c9;
    font-size: 16px;
    border-radius: 6px;
    resize: vertical;
    min-height: 20px;
`
export const FormButton = styled.button`
    padding: 10px 15px;
    font-size: 20px;
    background: #4949bf;
    text-align: center;
    width: 50%;
    color: #fff;
    border:0;
    margin:10px 0px 0px 0px;
    transition: 0.4s;
    &:hover{
        background: #414179e0;
    }

`

export const PictureLabelStyle = styled.label`
    ${FlexCenter}
    font-size: 20px;
    margin-bottom: 15px;
    background: #e9e9e9;
    padding: 8px;
    border-radius: 10px;

`

export const PriviewImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100%;
`