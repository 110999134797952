import React from "react";
import { StyleSheetManager } from "styled-components";
import Marquee from "react-marquee-slider";

const TopCarousel = () => {
  const marqueeData = [
    {
      id: 1,
      jobTitle: "Electrical engineer",
      skills:'System Design, Prototyping,Energy Efficiency,Documentation etc',
    },
    {
      id: 2,
      jobTitle: "Plumbing engineer",
      skills:'Administrative skills, Analytical skills,Technical skills,Physical skills etc',
    },
    {
      id: 3,
      jobTitle: "Mep Engineer",
      skills:'AutoCAD, Energy efficiency,Coordination,Problem solving etc',
    },
    {
      id: 4,
      jobTitle: "Mechanical Engineer",
      skills:'Machine Design,CAD/CAM,FEA and CFD, Structural Analysis',
    },
    {
      id: 5,
      jobTitle: "HVAC engineer",
      skills:'Problem solving, Customer service,Ductwork design,Heating etc',
    },
    {
      id: 6,
      jobTitle: "Green building engineer",
      skills:'Ability to work independently, Energy modelling and analysis  etc',
    },
    {
      id: 6,
      jobTitle: "BIM engineer",
      skills:'Collaboration, 3D modeling,Project management, Technical etc',
    }
  ];
  return (
    <>
      <StyleSheetManager shouldForwardProp={(prop) => prop !== "paused"} className="marquee">
        <Marquee velocity={30} className="marquee-slider">
          {marqueeData.map((item) => (
            <div
              key={item.id}
              className="team_cardWrapper"
              //   style={{ width: "273px", margin: "0 10px" }}
            >
              <div className="team__content">
                <h3>{item.jobTitle}</h3>
                {/* <p className="">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.62403 4.07983C6.0552 4.07983 4.7834 5.21537 4.7834 6.61613C4.7834 8.01688 6.0552 9.15242 7.62403 9.15242C9.19287 9.15242 10.4647 8.01688 10.4647 6.61613C10.4647 5.21537 9.19287 4.07983 7.62403 4.07983ZM6.20372 6.61613C6.20372 5.91575 6.83962 5.34798 7.62403 5.34798C8.40845 5.34798 9.04435 5.91575 9.04435 6.61613C9.04435 7.31651 8.40845 7.88428 7.62403 7.88428C6.83962 7.88428 6.20372 7.31651 6.20372 6.61613Z"
                        fill="#A3A3A3"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.22972 15.3864C7.22993 15.3866 7.23011 15.3867 7.62403 14.8591L7.22972 15.3864C7.46827 15.5284 7.77942 15.5287 8.01796 15.3867L7.62403 14.8591C8.01796 15.3867 8.01775 15.3868 8.01796 15.3867L8.02037 15.3852L8.0251 15.3824L8.04138 15.3726C8.05521 15.3642 8.07493 15.3521 8.10015 15.3365C8.15059 15.3053 8.22308 15.2599 8.31445 15.201C8.49714 15.0832 8.75577 14.9112 9.06507 14.6913C9.68254 14.2523 10.5077 13.6181 11.3353 12.8381C12.9614 11.3056 14.7256 9.09025 14.7256 6.61613C14.7256 4.93446 13.9774 3.32167 12.6456 2.13255C11.3138 0.943431 9.50749 0.275391 7.62403 0.275391C5.74058 0.275391 3.93427 0.943431 2.60246 2.13255C1.27066 3.32167 0.522461 4.93446 0.522461 6.61613C0.522461 9.09025 2.28668 11.3056 3.91279 12.8381C4.74036 13.6181 5.56552 14.2523 6.183 14.6913C6.4923 14.9112 6.75093 15.0832 6.93361 15.201C7.02499 15.2599 7.09747 15.3053 7.14791 15.3365C7.17314 15.3521 7.19286 15.3642 7.20669 15.3726L7.22296 15.3824L7.22769 15.3852L7.22972 15.3864ZM3.60678 3.02926C4.67222 2.07797 6.11727 1.54354 7.62403 1.54354C9.1308 1.54354 10.5758 2.07797 11.6413 3.02926C12.7067 3.98056 13.3053 5.27079 13.3053 6.61613C13.3053 8.58053 11.8738 10.4867 10.3042 11.966C9.53392 12.6919 8.76123 13.2862 8.18032 13.6993C7.84681 13.9364 7.40125 13.9364 7.06775 13.6993C6.48684 13.2862 5.71414 12.6919 4.94387 11.966C3.37427 10.4867 1.94278 8.58053 1.94278 6.61613C1.94278 5.27079 2.54133 3.98056 3.60678 3.02926Z"
                        fill="#A3A3A3"
                      />
                    </svg>
                  </span>
                  {item.loaction}
                </p> */}
                <p><span>Skills: </span> {item.skills}</p>
                <p>Any Graduate, BE/B.TECH</p>
              </div>
              {/* <button className="view_jobs">
                View jobs
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                  >
                    <path
                      d="M1.7416 0.630474C1.49398 0.37578 1.09251 0.37578 0.844894 0.630474C0.597275 0.885168 0.597275 1.29811 0.844894 1.5528C2.71349 3.47479 2.71349 6.53471 0.844894 8.45669C0.597275 8.71139 0.597275 9.12433 0.844894 9.37903C1.09251 9.63372 1.49398 9.63372 1.7416 9.37903L5.54602 5.46591C5.79364 5.21122 5.79364 4.79828 5.54602 4.54358L1.7416 0.630474Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button> */}
            </div>
          ))}
        </Marquee>
      </StyleSheetManager>
    </>
  );
};

export default TopCarousel;
