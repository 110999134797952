export const constant = {
    LOGIN_LOADING: 'login_loading',
    LOGIN_SUCCESS: 'login_success',
    LOGIN_FAILURE: 'login_failure',

    REGISTER_LOADING: 'register_loading',
    REGISTER_SUCCESS: 'register_success',
    REGISTER_FAILURE: 'register_failure',

    LOGOUT_LOADING: 'logout_loading',
    LOGOUT_SUCCESS: 'logout_success',
    LOGOUT_FAILURE: 'logout_failure',
}