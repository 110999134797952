import React from 'react'
import { FormButton, FormContainerStyle, FormControls, FormHeader, FormStyle } from '../styled';
import InputField from '../../../components/inputs/input-field';
import TextAreaField from '../../../components/inputs/textarea-field';
const JobForm = (props) => {
    const { type, jobValue, handleChange, onSubmit } = props;
    console.log(jobValue, "jobValue")

    const isEdit = type === "create";



    return (
        <FormContainerStyle>
            <FormStyle onSubmit={onSubmit}>
                <FormHeader>{isEdit ? 'Create' : 'Update'} Job</FormHeader>

                <FormControls>
                    <InputField
                        type={'text'}
                        placeholder={'Enter your job title'}
                        name='jobTitle'
                        value={jobValue?.jobTitle}
                        onChange={handleChange}
                    />
                </FormControls>
                <FormControls>
                    <TextAreaField
                        type={'text'}
                        placeholder={'Enter your job description'}
                        name='jobDesc'
                        value={jobValue?.jobDesc}
                        onChange={handleChange}

                    />
                </FormControls>
                <FormControls>
                    <InputField
                        type={'text'}
                        placeholder={'Enter your applicant exp'}
                        name='applicantExp'
                        value={jobValue?.applicantExp}
                        onChange={handleChange}

                    />
                </FormControls>
                <FormControls>
                    <InputField
                        type={'text'}
                        placeholder={'Enter your category'}
                        name='category'
                        value={jobValue?.category}
                        onChange={handleChange}

                    />
                </FormControls>
                <FormControls>
                    <InputField
                        type={'text'}
                        placeholder={'Enter your job company name'}
                        name='companyName'
                        value={jobValue?.companyName}
                        onChange={handleChange}

                    />
                </FormControls>
                <FormControls>
                    <InputField
                        type={'text'}
                        placeholder={'Enter your job company location'}
                        name='companyLocation'
                        value={jobValue?.companyLocation}
                        onChange={handleChange}

                    />
                </FormControls>


                <FormControls className='t-center'>
                    <FormButton type='submit' >{isEdit ? 'Publish' : 'Create'}</FormButton>
                </FormControls>
            </FormStyle>
        </FormContainerStyle>
    )
}

export default JobForm