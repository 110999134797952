import React, { useContext, useEffect, useState } from 'react'

import { Card, Col, Container, Row } from 'react-bootstrap'
import { DataContext } from '../../../context/context-job';
import Loader from '../../../components/loader';





const JobPages = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const { fetchAllJob, state: { jobs, isLoading } } = useContext(DataContext);

    useEffect(() => {
        (async () => {
            await fetchAllJob();
        })()
    }, []);

    // if (isError) {
    //     return <h1>{error?.message}</h1>
    // }
    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <Container className='p-2'>
                <Row>
                    {
                        jobs?.length && jobs?.map((job) => (
                            <Col md={3}>
                                <Card>
                                    <Card.Header>{job?.jobTitle}</Card.Header>
                                    <Card.Body>
                                        <Card.Subtitle>{job?.jobDesc}</Card.Subtitle>
                                        <Card.Title>{job?.companyName}</Card.Title>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </Container>

        </>
    )
}

export default JobPages