import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

const AboutMission = () => {
  const missionData = [
    {
      desc: "Seeing individuals thrive",
    },
    {
      desc: "Building a dynamic workforce",
    },
    {
      desc: "Championing ethical practices",
    },
    {
      desc: "Bridging the gap between talent and opportunity",
    },
  ];
  const missionImg = "missionImg.png";
  const missionImage = process.env.PUBLIC_URL + "/images/" + missionImg;

  return (
    <>
      <Container fluid style={{ backgroundImage: `url(${missionImage})`, backgroundSize: 'cover' }} className="full_mission_container">
        <Row>
          <Col md={6} sm={12} className="mission_conatainer__coumn">
            <div>
              <p className="mission_heading">Our Mission:</p>
              <Row>
                {missionData.map((mission, index) => (
                  <Col key={index} md={6}>
                    <Card className="mission_titleCard">
                      <Card.Body>
                        <Card.Title className="mission_desc">{mission.desc}</Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          <Col md={6} sm={12} className="mission_content__container">
            <p className="mission_content">At AS CONSULTANCY PVT LTD, our mission is more than just connecting people with jobs. We strive to empower individuals on their unique career journeys, unlocking their full potential and propelling them towards fulfilling professional aspirations.</p>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default AboutMission;
