import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import SubMenu from './sub-menu'

const MenuItem = ({ menuActive, menu, index, handleLogout }) => {
    const [dropDown, setDropDown] = useState(false);

    const onHandleDropDown = (id) => {
        if (id === index) {
            setDropDown(!dropDown)
        } else {
            setDropDown(false)
        }
    }
    return (
        <>
            {menu?.submenu
                ?
                <>
                    <NavLink
                        to={menu.path}
                        key={menu.id}
                        className='text-white d-flex justify-content-between align-items-center'
                        onClick={() => onHandleDropDown(menu?.id, menu?.path)}
                    >
                        <div className="d-flex align-items-center">
                            <span className='menuIcon d-flex justify-content-center align-items-center'>{menu.icon}</span>
                            <span className={`mx-2 ${menuActive ? 'text-warning' : 'opacity-0 invisible'}`}>{menu.title}</span>
                        </div>
                        <div className="arrowIcons">
                            {
                                menu.submenu && dropDown
                                    ?
                                    <span>{menu.closeIcon}</span>
                                    :
                                    menu.submenu
                                    &&
                                    <span>{menu.openIcon}</span>
                            }
                        </div>
                    </NavLink>
                    <SubMenu
                        submenu={menu.submenu}
                        dropDown={dropDown}
                        index={menu?.id}
                        menuActive={menuActive}
                    />
                </>
                :
                <NavLink
                    to={menu.path}
                    key={menu?.id}
                    onClick={() => handleLogout(menu?.path)}
                    className='d-flex justify-content-between align-items-center'
                >
                    <div className="navlinks d-flex align-items-center">
                        <span className='menuIcon d-flex justify-content-center align-items-center'>{menu.icon}</span>
                        <span className={`mx-2 ${menuActive ? '' : 'opacity-0 invisible'}`}>{menu.title}</span>
                    </div>

                </NavLink>
            }
        </>
    )
}

export default MenuItem