import React from 'react'
import { Link } from 'react-router-dom';

const ApplyButton = ({ handleApplyJob, isApply, isLoggedIn }) => {
    return (
        <div className="latest_job__apply">
            {(
                isLoggedIn ? (
                    <button onClick={handleApplyJob}>
                        Apply
                    </button>
                ) : (
                    <button>
                        <Link to="/register">Apply</Link>
                    </button>
                )
            )}
        </div>
    );
}

export default ApplyButton