import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  const logo = "company_logo.png";
  const c_logo = process.env.PUBLIC_URL + "/images/" + logo;

  const gmailImg = "mail.png";
  const gmail = process.env.PUBLIC_URL + "/images/" + gmailImg;

  const facebookImg = "facebook.png";
  const facebook = process.env.PUBLIC_URL + "/images/" + facebookImg;

  const linkedInImg = "linkedIn.png";
  const linkedIn = process.env.PUBLIC_URL + "/images/" + linkedInImg;

  const instgramImg = "instagram.png";
  const instagram = process.env.PUBLIC_URL + "/images/" + instgramImg;

  return (
    <>
      <div className="footer_main_container">
        <div className="footer_full_container">
          <Container fluid className="main_menu__footer">
            <Row className="">
              <Col>
                <div className="footer__logo">
                  <img src={c_logo} />
                </div>
              </Col>
              <Col>
                <div className="footer_list__column">
                  <p className="address_title">Follow </p>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about-page">About Us</Link>
                    </li>
                    <li>
                      <Link to="/job">Find Job</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col className="adress__wrapper">
                <div className="footer_address__column">
                  <div>
                    <p className="address_title">Address</p>
                    <p>Noida Sec 62, Noida</p>
                  </div>
                  <div>
                    <p className="address_title">Info</p>
                    <p>+91 9818555881</p>
                    <p>info@asbusinesses.in</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="copyright">
          <div> © 2023 AS Consultancy Pvt. Ltd. All Rights Reserved.</div>
          <div>
            <Link to="">
              <img src={facebook} className="facebook" />
            </Link>
            <Link to="">
              <img src={linkedIn} className="linkedIn" />
            </Link>
            <Link to="">
              <img src={gmail} className="gmail" />
            </Link>
            <Link to="">
              <img src={instagram} className="instagram" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
