

import React from 'react'
import { useReducer } from 'react';
import { createContext } from 'react'
import { reducer } from './reducer';
import { constant } from './constant';
import createAxiosInstance from '../../helpers/axios/private-instance';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { VIEW_BLOGS } from '../../helpers/sidebar-data/route-urls';

const initialState = {
  blogs: [],
  jobs: [],
  isApply: [],
  isLoading: false,
  status: false,
  isError: null,
  message: ''
}
// -----------------BLOG ------------------------ //

const CREATE_BLOG = '/blogs/create-blog'
const FETCH_ALL_BLOG = '/blogs/view-blogs'


// -----------------JOB ------------------------ //


const CREATE_JOB = '/jobs/create-job'
const FETCH_ALL_JOB = '/jobs/view-jobs'

// -----------------JOB APPLY AND CHECK STATUS ---------------------------//

const APPLY_JOB = '/jobs/apply-job'
const CHECK_JOB_STATUS = '/jobs/get-job-status'


export const DataContext = createContext();

const DataProvider = ({ children }) => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  /**
   * @CREATE_BLOG | BLOG create function
   * @param {*} blog | blog Data
   * @Author | Anuska Sinha
   */
  const createBlog = async (blog) => {
    dispatch({ type: constant.CREATE_BLOG_LOADING });
    try {
      const axiosPrivateInstance = createAxiosInstance(blog)
      const response = await axiosPrivateInstance.post(CREATE_BLOG, blog);
      dispatch({ type: constant.CREATE_BLOG_SUCCESS, payload: response.data })
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        navigate(VIEW_BLOGS)
      }
    } catch (error) {
      dispatch({ type: constant.CREATE_BLOG_FAILURE, payload: error.message })
    }
  }


  /**
   * @FETCH_BLOG | BLOG fetch function
   * @param {*} __ | __
   * @Author | Anuska Sinha
   */
  const fetchAllBlog = async () => {
    dispatch({ type: constant.FETCH_BLOG_LOADING });
    let response;
    try {
      const axiosPrivateInstance = createAxiosInstance()
      response = await axiosPrivateInstance.get(FETCH_ALL_BLOG);
      dispatch({ type: constant.FETCH_BLOG_SUCCESS, payload: response.data })
    } catch (error) {
      dispatch({ type: constant.FETCH_BLOG_FAILURE, payload: response?.data })
    }
  }



  // -----------------JOB ------------------------ //




  /**
   * @CREATE_JOB | JOB create function
   * @param {*} job | job Data
   * @Author | Anuska Sinha
   */

  const createJob = async (jobData) => {
    dispatch({ type: constant.CREATE_JOB_LOADING });
    try {
      const axiosPrivateInstance = createAxiosInstance(jobData)
      const response = await axiosPrivateInstance.post(CREATE_JOB, jobData);
      dispatch({ type: constant.CREATE_JOB_SUCCESS, payload: response.data })
      return response?.data
    } catch (error) {
      dispatch({ type: constant.CREATE_JOB_FAILURE, payload: error.message })
    }
  }


  /**
   * @FETCH_JOB | JOB fetch function
   * @param {*} __ | __
   * @Author | Anuska Sinha
   */

  const fetchAllJob = async () => {
    dispatch({ type: constant.FETCH_JOB_LOADING });
    let response;
    try {
      const axiosPrivateInstance = createAxiosInstance()
      response = await axiosPrivateInstance.get(FETCH_ALL_JOB);
      dispatch({ type: constant.FETCH_JOB_SUCCESS, payload: response?.data })
    } catch (error) {
      dispatch({ type: constant.FETCH_JOB_FAILURE, payload: response?.data })
    }
  }



  //  -----------------------APPLY JOB------------------------//

  /**
   * @APPLY_JOB | JOB apply function
   * @param {*} job | job Data
   * @Author | Anuska Sinha
   */

  const applyJob = async (Ids) => {
    const { jobId, id, isApply } = Ids;
    const jobData = {
      jobId,
      isApply
    }
    dispatch({ type: constant.APPLY_JOB_LOADING });
    try {
      const axiosPrivateInstance = createAxiosInstance(jobData)
      const response = await axiosPrivateInstance.post(`${APPLY_JOB}/${id}`, jobData);
      dispatch({ type: constant.APPLY_JOB_SUCCESS, payload: response.data })
      if(response?.data?.status){
        toast.success(response?.data?.message)
      }
    } catch (error) {
      toast.error("Already Applied")
      dispatch({ type: constant.APPLY_JOB__FAILURE, payload: error.message })
    }
  }



  /**
 * @CHECK_JOB_STATUS | JOB status check function
 * @param {*} userId | by user id
 * @Author | Anuska Sinha
 */

  const checkJobStatus = async (userId) => {
    dispatch({ type: constant.GET_BLOG_BY_ID_LOADING });
    try {
      const axiosPrivateInstance = createAxiosInstance()
      const response = await axiosPrivateInstance.get(`${CHECK_JOB_STATUS}/${userId}`);
      console.log(response.data, "job response status================>")

      dispatch({ type: constant.GET_JOB_STATUS_SUCCESS, payload: response.data?.data })
    } catch (error) {
      dispatch({ type: constant.GET_JOB_STATUS_FAILURE, payload: error.message })
    }
  }


  const value = {
    state,
    dispatch,
    createBlog,
    fetchAllBlog,
    createJob,
    fetchAllJob,
    applyJob,
    checkJobStatus
  }


  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  )
}

export default DataProvider
